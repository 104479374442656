import "babel-polyfill"
import $ from "jquery"
import "slick-carousel"
import nav from "./util/nav"
import footer from "./util/footer"
import { validate } from "jquery-validation"
import { lazySizes } from "lazysizes"
import active from "./util/active"
import accordion from "./util/accordion"
import tab from "./util/tab"
import datePicker from "./util/datePicker"
import smoothScroll from "./util/smoothScroll"
import customize from "./util/customize"
import { headerShow, toTop, footerBanner } from "./util/scrollEvent"
import {
  formValid,
  formReset,
  enterDisallow,
  errorJump,
  submitCheck,
} from "./util/formValid"
import moment from "moment"
import { slide } from "./util/slide"

require("moment/locale/ja")

// lazySizes
lazySizesConfig = lazySizesConfig || {}
lazySizesConfig.lazyClass = "lazy"
lazySizesConfig.srcAttr = "data-src"
lazySizesConfig.loadMode = 2

$(() => {
  active()
  accordion()
  tab()
  smoothScroll()
  customize()
  formValid()
  formReset()
  enterDisallow()
  errorJump()
  submitCheck()
  toTop()
  footer()
  nav()
})

var selectColor = function () {
  // 現在選択されてる項目によって色設定
  var select = $("[data-target-select]")

  if (select.find("option:selected").hasClass("disabled-color")) {
    $("select").css({ color: "#959595" })
  }

  // 項目が変更された時、条件によって色変更
  select.on("change", function () {
    if ($(this).find("option:selected").hasClass("disabled-color")) {
      $(this).css({ color: "#959595" })
    } else {
      $(this).css({ color: "#434343" })
    }
  })
}

$(function () {
  selectColor()
})

const hopeTimes = $("#hopeTime").html()

window.onload = function () {
  if (moment().hours() >= 18) {
    $("#date").datepicker("option", "minDate", "+1d")
  }
  var code = sessionStorage.getItem("code")
  $("#code").text(code)

  writeInput()
  writeInputCallentry()
}

$("#date").change(function () {
  var dateFormat = "YYYY/MM/DD"
  var hourFormat = "HH:mm"
  var now = moment()
  var input = moment($(this).val())

  $("#hopeTime").html(hopeTimes)

  if (input.isSame(now.format(dateFormat), "days")) {
    if (6 <= now.hours() && now.hours() < 18) {
      var hour = 0
      var time = ""
      if (30 < now.minute()) {
        hour = now.hours() + 4
        time = hour + ":00"
      } else {
        hour = now.hours() + 3
        time = hour + ":30"
      }

      $("#hopeTime option").each(function () {
        if ($(this).val().indexOf(time) >= 0) {
          $(this).remove()
          return false
        } else if ($(this).val() === "選択してください") {
          //Through
        } else {
          $(this).remove()
        }
      })
    }
  }
})

$("#inputs").submit(function () {
  var tmpArray = $(this).serializeArray()
  var inputTime = tmpArray.find(function (input) {
    return input.name === "time"
  })

  if (!inputTime) {
    //時間が日存在時に適当な値を入れておいてあげる
    tmpArray.push({
      name: "time",
      value: "",
    })
  }

  sessionStorage.setItem("form", JSON.stringify(tmpArray))
  return true
})

$("#callentry").submit(function () {
  var tmpArray = $(this).serializeArray()
  var inputTime = tmpArray.find(function (input) {
    return input.name === "time"
  })

  if (!inputTime) {
    //時間が日存在時に適当な値を入れておいてあげる
    tmpArray.push({
      name: "time",
      value: "",
    })
  }

  sessionStorage.setItem("callentry", JSON.stringify(tmpArray))
  return true
})

var flag = false

const getUniqueStr = () => {
  const id = new Date().getTime().toString(16)
  const unique_id =
    id.substr(0, 8) + Math.floor(1000 * Math.random()).toString(16)
  return unique_id
}

var countId

function getCount(countPath) {
  return $.ajax({
    url: countPath,
    dataType: "text",
    async: false,
  })
}

const contactData = async (data, type) => {
  const dataJson = JSON.parse(data)
  // APIエンドポイント ※テスト・本番で異なる
  // const url = "https://dev.cd.012grp.tech/api/v2/entry"
  const url = "https://prod.cd.012grp.tech/api/v2/entry"
  // 乱数のみでは不安なため時間もプラス
  const unique_id = getUniqueStr()
  const dataObj = {}
  dataJson.forEach((data, i) => {
    dataObj[data.name] = data.value
  })

  // 1 = 申し込み
  // 2 = 電話相談予約
  let countPath
  if (type == "1") {
    countPath = "../../phpCount/count.txt"
  } else if (type == "2") {
    countPath = "../../callentryForm/phpCount/count.txt"
  } else if (type == "3") {
    countPath = "../../area/phpCount/count.txt"
  }
  getCount(countPath)
    .done(function (result) {
      countId = Number(result) + 1
      return result
    })
    .fail(function (result) {})
  let sendData
  // console.log(unique_id)
  if (type == "1") {
    sendData = {
      name: `${dataObj.firstname} ${dataObj.lastname} `, //お名前
      name_kana: `${dataObj.firstkana} ${dataObj.lastkana}`, //お名前（カナ）
      zip: dataObj.zip, //郵便番号
      address: dataObj.pref, //都道府県
      address_2: `${dataObj.addr01} ${dataObj.addr02}`, //丁目番地
      phone: dataObj.tel, //電話番号
      mail: dataObj.mail, //メールアドレス
      subject: "お申し込み【ドコモ光/アフィリ】", // 必須
      click_id: "h-docomo_entry"+countId, // カウントID、必須
      affiliate_id: "h-dkyrm"+countId, // 乱数、必須
      others: JSON.stringify({
        "【お名前】": `${dataObj.firstname} ${dataObj.lastname} `,
        "【フリガナ】": `${dataObj.firstkana} ${dataObj.lastkana}`,
        "【電話番号】": dataObj.zip,
        "【住所】": `${dataObj.zip} ${dataObj.pref} ${dataObj.addr01} ${dataObj.addr02}`,
        "【メールアドレス】": dataObj.mail,
        "【電話番号】": dataObj.tel,
        "【ご利用中のインターネット環境】": dataObj.provider,
        "【引っ越し有無】": dataObj.moving,
        "【ご要望・ご質問など】": dataObj.note,
      }), // JSON形式
    }
  } else if (type == "2") {
    sendData = {
      name: `${dataObj.firstname} ${dataObj.lastname}`, //お名前
      name_kana: `${dataObj.firstkana} ${dataObj.lastkana}`, //お名前（カナ）
      phone: dataObj.tel, //電話番号
      subject: "電話折り返し予約【ドコモ光/アフィリ】", // 必須
      click_id: "h-docomo_callentry"+countId, // カウントID、必須
      affiliate_id: "h-dkyrm"+countId, // 乱数、必須
      others: JSON.stringify({
        "【お名前】": `${dataObj.firstname} ${dataObj.lastname}`,
        "【フリガナ】": `${dataObj.firstkana} ${dataObj.lastkana}`,
        "【電話番号】": dataObj.tel,
        "【繋がりやすい時間帯】": dataObj.timezone,
        "【引っ越し有無】": dataObj.moving,
        "【ご要望・ご質問など】": dataObj.note,
      }), // JSON形式
    }
  } else if (type == "3") {
    sendData = {
      areaLineRadio: `${dataObj.areaLineRadio}`,
      phoneradio: `${dataObj.phoneradio}`,
      name: `${dataObj.fullName}`, //お名前
      phone: dataObj.tel, //電話番号
      mail: dataObj.mail, //メールアドレス
      dateRadio: `${dataObj.dateRadio}`,
      timezone: `${dataObj.timezone}`,
      zip: dataObj.zip, //郵便番号
      address: dataObj.pref, //都道府県
      address_2: `${dataObj.addr01}`, //丁目番地
      subject: "無料相談【ドコモ光/アフィリ】", // 必須
      click_id: "h-docomo_area"+countId, // カウントID、必須
      affiliate_id: "h-dkyrm"+countId, // 乱数、必須
      others: JSON.stringify({
        "【ご利用中のネット回線】": `${dataObj.areaLineRadio}`,
        "【ドコモスマホ】": `${dataObj.phoneradio}`,
        "【お名前】": `${dataObj.fullName}`,
        "【繋がりやすい日時】": `${dataObj.dateRadio} ${dataObj.timezone}`,
        "【電話番号】": dataObj.tel,
        "【メールアドレス】": dataObj.mail,
        "【住所】": `${dataObj.zip} ${dataObj.pref} ${dataObj.addr01}`,
      }), // JSON形式
    }
  }
  let apiToken
  if (type == "1") {
    // dev
    // apiToken = "7TYLbEJbvGwtjLxQt8hqBROOR"
    // prod
    apiToken = "koEPbkJTrcws8t7OUPV6PPw1z"
  } else if (type == "2") {
    // dev
    // apiToken = "CkWXjGiAZVF7Ua6i388FXMpMb"
    // prod
    apiToken = "Y8X2ruu1fFNqbAHoMoG81Tna1"
  }
  else if (type == "3") {
    // dev
    // apiToken = "PVNl4FRjCzcGjHXYouII"
    // prod
    apiToken = "7bTMsuJenjjFl5QlY9FY"
  }

  //console.log(sendData)
  //console.log(apiToken)

  try {
    if(location.hostname === "localhost" ) {
      return true;
    }
    const result = await $.ajax({
      url: url,
      method: "post",
      type: "json",
      data: sendData,
      beforeSend: function (request) {
        request.setRequestHeader("x-api-key", apiToken) // 管理画面サイト詳細記載のtoken
      },
    })
    //console.log(result)
    return true
  } catch (error) {
    return false
  }
}

$("#submitOK").click(async () => {
  if (flag) return
  var inputs = sessionStorage.getItem("form")
  sessionStorage.clear()
  flag = true
  const target = document.getElementById("loader")
  target.classList.remove("is-hidden")

  const result = await contactData(inputs, "1")
  //console.log(result)
  if (result) {
    $.ajax({
      url: "../mail.php",
      type: "POST",
      dataTypes: "json",
      data: JSON.parse(inputs),
    }).done(function (res) {
      if (res) {
        target.classList.add("is-hidden")
        sessionStorage.clear()
        sessionStorage.setItem("code", res)
        location.href = "./thanks.php"
      }
    })
  } else {
    target.classList.add("is-hidden")
    alert(
      "送信中にエラーが発生しました。恐れ入りますがしばらくしてから再度お試しください。"
    )
    history.back()
  }
})
var flag = false
$("#submitCallentry").click(async () => {
  if (flag) return
  var callentry = sessionStorage.getItem("callentry")
  sessionStorage.clear()
  const target = document.getElementById("loader")
  target.classList.remove("is-hidden")

  const result = await contactData(callentry, "2")
  console.log("リザルト", result)
  flag = true
  //console.log(result)
  if (result) {
    $.ajax({
      url: "../callentryForm/mail.php",
      type: "POST",
      dataTypes: "json",
      data: JSON.parse(callentry),
    }).done(function (res) {
      if (res) {
        target.classList.add("is-hidden")
        sessionStorage.clear()
        sessionStorage.setItem("code", res)
        location.href = "./thanks.php"
      }
    })
  } else {
    target.classList.add("is-hidden")
    console.log("エラー")
    // alert(
    //   "送信中にエラーが発生しました。恐れ入りますがしばらくしてから再度お試しください。"
    // )
    // history.back()
  }
})
$("#back").click(function () {
  history.back()
})
$("#submitArea").click(async () => {
  let tmpArray = $("#areaInput").serializeArray()
  let inputData = JSON.stringify(tmpArray)
  if (flag) return
  sessionStorage.clear()

  const result = await contactData(inputData, "3")
  flag = true
  if (result) {
    const honeypotValue = tmpArray.find(item => item.name === "honeypot").value;
    if(honeypotValue) {
      return;
    }
    $.ajax({
      url: "../area/mail.php",
      type: "POST",
      dataTypes: "json",
      data: JSON.parse(inputData),
    }).done(function (res) {
      if (res) {
        // target.classList.add("is-hidden")
        sessionStorage.clear()
        sessionStorage.setItem("code", res)
        location.href = "./thanks.php"
      }
    })
  } else {
    alert(
      "送信中にエラーが発生しました。恐れ入りますがしばらくしてから再度お試しください。"
    )
    // history.back()
  }
})

function writeInput() {
  var inputs = JSON.parse(sessionStorage.getItem("form" || "null"))

  dispConfirm(inputs, "firstname", "inputFirstName")
  dispConfirm(inputs, "lastname", "inputLastName")
  dispConfirm(inputs, "firstkana", "inputFistKana")
  dispConfirm(inputs, "lastkana", "inputLastKana")
  dispConfirm(inputs, "tel", "inputTel")
  dispConfirm(inputs, "mail", "inputEmail")
  dispConfirm(inputs, "zip", "inputZip")
  dispConfirm(inputs, "pref", "inputPref")
  dispConfirm(inputs, "addr01", "inputAddr01")
  dispConfirm(inputs, "addr02", "inputAddr02")
  dispConfirm(inputs, "provider", "inputProvider")
  dispConfirm(inputs, "moving", "inputMoving")
  dispConfirm(inputs, "note", "inputRemark")
}

function dispConfirm(inputs, name, id) {
  if ($("#inputEmail").length) {
    var tmp = inputs.find(function (input) {
      return input.name === name
    })
    if (tmp.value !== "0") {
      $("#" + id).text(tmp.value)
    } else {
      $("#" + id).text("")
    }
  }
}

function writeInputCallentry() {
  var callentry = JSON.parse(sessionStorage.getItem("callentry" || "null"))

  dispConfirmCallentry(callentry, "firstname", "inputFirstName")
  dispConfirmCallentry(callentry, "lastname", "inputLastName")
  dispConfirmCallentry(callentry, "firstkana", "inputFistKana")
  dispConfirmCallentry(callentry, "lastkana", "inputLastKana")
  dispConfirmCallentry(callentry, "tel", "inputTel")
  dispConfirmCallentry(callentry, "timezone", "inputTimezone")
  dispConfirmCallentry(callentry, "moving", "inputMoving")
  dispConfirmCallentry(callentry, "note", "inputRemark")
}

function dispConfirmCallentry(callentry, name, id) {
  if ($("#inputFirstName").length) {
    var tmp = callentry.find(function (input) {
      return input.name === name
    })
    if (tmp.value !== "0") {
      $("#" + id).text(tmp.value)
    } else {
      $("#" + id).text("")
    }
  }
}

function dispConfirmOnArray(inputs, name, id) {
  var tmp = inputs.filter(function (v) {
    return v.name === name
  })

  var values = []

  tmp.forEach(function (v) {
    values.push(v.value)
  })

  var str = values.join(",")

  $("#" + id).text(str)
}
