import $ from 'jquery'
import 'slick-carousel'

$('.slideBox').slick({
    infinite: true,
    autoplay: true,
    autoplaySpeed: 3000,
    dots: false,
    centerMode: true,
    slidesToShow: 1,
    arrows: false,
    centerPadding: '10%',
    variableWidth: true,
    responsive: [
        {
            breakpoint: 768, //767px以下のサイズに適用
            settings: {
                centerMode: false,
                centerPadding: '0',
                variableWidth: false,
            }
        }
    ]
});

// window.addEventListener('load', () => {
//     $('.slideBox').slick({
//         autoplay: true,
//     });
// });