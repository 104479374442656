import $ from "jquery"
import * as AutoKana from "vanilla-autokana"

let target = target || {}
target = {
  form: $("[data-target-form]"),
  header: $("[data-target-header]"),
}

let trigger = trigger || {}
trigger = {
  reset: $("[data-trigger-reset]"),
  submit: $("[data-trigger-submit]"),
}

//自動フリガナ入力
if ($('input[name = "name"]').length) {
  AutoKana.bind("#inputFirstName", "#inputFirstKana", { katakana: true })
}

export function formValid() {
  if (!target.form.length) return

  $.validator.setDefaults({
    debug: false,
    success: null,
    errorClass: "c-form__error",
    errorElement: "div",
  })

  $.validator.addMethod("numberHyphen", function (value, element) {
    return this.optional(element) || /^[\d,-]+$/.test(value)
  })
  $.validator.addMethod("kanaInput", function (value, element) {
    return this.optional(element) || /^[ァ-ヶｦ-ﾟー゛　 ]+$/u.test(value) //カタカナのみ
  })

  $.extend($.validator.messages, {
    email: "正しいメールアドレスの形式で入力して下さい",
  })

  target.form.validate({
    errorPlacement: function (error, element) {
      if (element.attr("name") === "agree") {
        error.addClass("c-form__error--agree").insertAfter(element.parent())
      } else {
        error.insertAfter(element)
      }
      if (element.attr("name") === "areaLineRadio" || element.attr("name") === "phoneradio" || element.attr("name") === "dateRadio") {
        let parent = element.parent()
        error.addClass("c-form__error--radio").insertAfter(parent.parent())
      }
    },
    rules: {
      fullName: {
        required: true
      },
      firstname: {
        required: true,
      },
      lastname: {
        required: true,
      },
      firstkana: {
        kanaInput: true,
      },
      lastkana: {
        kanaInput: true,
      },
      zip: {
        required: true,
        numberHyphen: true,
      },
      pref: {
        required: true,
      },
      addr01: {
        required: true,
      },
      addr02: {
        required: true,
      },
      tel: {
        required: true,
        numberHyphen: true,
      },
      mail: {
        required: true,
        email: true,
      },
      areaLineRadio: {
        required: true,
      },
      phoneradio: {
        required: true
      },
      dateRadio: {
        required: true
      },
      timezone: {
        required: true
      }
    },
    messages: {
      fullName: {
        required: "名前を入力してください"
      },
      firstname: {
        required: "姓を入力してください",
      },
      lastname: {
        required: "名を入力してください",
      },
      firstkana: {
        kanaInput: "カタカナで入力してください",
      },
      lastkana: {
        kanaInput: "カタカナで入力してください",
      },
      zip: {
        required: "郵便番号を入力してください",
        numberHyphen: "郵便番号を数字で入力してください",
      },
      pref: {
        required: "都道府県を入力してください",
      },
      addr01: {
        required: "市区町村を入力してください",
      },
      addr02: {
        required: "番地・建物名を入力してください",
      },
      tel: {
        required: "電話番号を入力してください",
        numberHyphen: "電話番号を数字で入力してください",
      },
      mail: {
        required: "メールアドレスを入力してください",
      },
      agree: {
        required: "プライバシーポリシーに同意するにチェックをしてください",
      },
      areaLineRadio: {
        required: "回線選択して下さい"
      },
      phoneradio: {
        required: "スマホを選択して下さい"
      },
      dateRadio: {
        required: "日時を選択して下さい"
      },
      timezone: {
        required: "時間帯を選択して下さい"
      }
    },
  })
}

export function errorJump() {
  trigger.submit.on("click", (e) => {
    const headerHeight = target.header.height()

    if (target.form[0]['honeypot'].value) {
      e.preventDefault();
      location.href = '/';
    }

    if (target.form.valid()) {
        trigger.submit.submit()
    } else {
      let errorPosition = target.form
        .find("input.c-form__error")
        .closest(".c-form__item")
        .offset().top
      $("html,body").animate(
        { scrollTop: errorPosition - headerHeight },
        "fast",
        "swing"
      )
    }
  })
}

export function formReset() {
  trigger.reset.on("click", (event) => {
    event.preventDefault()
    const validator = target.form.validate()
    validator.resetForm()
    $("input").val("")
    $("select").prop("selectedIndex", 0)
    $("textarea").val("")
  })
}

export function submitCheck() {
  if (!target.form.length) return

  validateForm(target.form)
  target.form.on("keyup change input", "[required]", () => {
    validateForm(target.form)
  })

  function validateForm(form) {
    let valid = form.validate().checkForm()
    if (valid) {
      trigger.submit.removeClass("is-disabled")
    } else {
      trigger.submit.addClass("is-disabled")
    }
  }
}

export function enterDisallow() {
  $(document).on("keypress", "input", (event) => {
    return event.which !== 13
  })
}
