import $ from 'jquery'

let trigger = trigger || {};
trigger = {
    accordion: $('[data-trigger-accordion]')
};

let target = target || {};
target = {
    accordion: $('[data-target-accordion]')
};

export default function() {
    if(!trigger.accordion.length) return;
    trigger.accordion.on('click',function() {
        $(this).toggleClass('is-active');
        $(this).next(target.accordion).stop(false,false).slideToggle(300,'swing');

        // data-target-textを取得し、テキストを切り替える
        let textElement = $(this).find('[data-target-text]');
        if (textElement.length) {
            let currentText = textElement.text();
            if (currentText === '開く') {
                textElement.text('閉じる');
            } else {
                textElement.text('開く');
            }
        }
    });
}