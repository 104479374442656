import $ from 'jquery'

let trigger=trigger||{};
trigger={
    tab: $('[data-trigger-tab]')
};

let target=target||{};
target={
    tab: $('[data-target-tab]')
};

export default function () {
    if (!trigger.tab.length) return;
    trigger.tab.on('click',function () {
        if (!$(this).hasClass('is-active')) {
            $(this).toggleClass('is-active');
        }
    });
}